@if require-font('Montserrat') {

/* montserrat-300 - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: local('Montserrat Light'), local('Montserrat-Light'),
       url('../fonts/montserrat-v13-latin_latin-ext-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v13-latin_latin-ext-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
       url('../fonts/montserrat-v13-latin_latin-ext-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v13-latin_latin-ext-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('../fonts/montserrat-v13-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v13-latin_latin-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
       url('../fonts/montserrat-v13-latin_latin-ext-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v13-latin_latin-ext-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
       url('../fonts/montserrat-v13-latin_latin-ext-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v13-latin_latin-ext-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500italic - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: fallback;
  src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
       url('../fonts/montserrat-v13-latin_latin-ext-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v13-latin_latin-ext-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('../fonts/montserrat-v13-latin_latin-ext-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v13-latin_latin-ext-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600italic - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-display: fallback;
  src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
       url('../fonts/montserrat-v13-latin_latin-ext-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v13-latin_latin-ext-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('../fonts/montserrat-v13-latin_latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v13-latin_latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
       url('../fonts/montserrat-v13-latin_latin-ext-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v13-latin_latin-ext-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

} // end @if